import React from "react"
import SEO from "../components/seo"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Link, graphql } from "gatsby"

import List from "../layouts/list"
import Main from "../components/main"
import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import Container from "../layouts/container"
import Breadcrumbs from "../components/chrome/breadcrumbs"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PageTags = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <List>
    <SEO
      title="All tags"
    />
    <PageHeader headerText="All tags" />

    <Main>
      <Container>
        <Breadcrumbs title="Tags" path="/tags" />
      </Container>
      <div className="row">
        <div className="col--16">
          <Container>
            <h1 className="h1">Tags</h1>
            <ul className="tags">
              {group.map(tag => (
                <li key={tag.fieldValue} className="tag">
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="tag__link">
                    {tag.fieldValue}&nbsp;({tag.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </Container>
        </div>
      </div>
    </Main>

    <PageFooter />
  </List>
)

PageTags.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`

export default PageTags